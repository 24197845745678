import { produce } from 'immer';
import { useCallback, useEffect, useState } from 'react';
import {
  AnswerTypes,
  getSurvey,
  QuestionModel,
  submitSurvey,
  Survey
} from 'src/api';
import { getQueryParam } from 'src/core';
import { months } from '../../app/pages/survey/utils';

/**
 * Use Form Data
 */
const useFormData = () => {
  const [loading, setLoading] = useState(true);
  const [survey, setSurvey] = useState(null);
  const [data, setData] = useState(null);
  const [results, setResults] = useState({} as any);
  const [isQuestionAnswered, setIsQuestionAnswered] = useState(false);
  const [isQuestionTouched, setIsQuestionTouched] = useState(false);
  const [isSingleError, setIsSingleError] = useState(false);
  const [isIndividualSurvey, setIsIndividualSurvey] = useState(false);
  const { questions } = data || ({} as Survey);
  const [validation, setValidation] = useState([]);
  const [clientId, setClientId] = useState('');
  const [mobileConfirmation, setMobileConfirmation] = useState(false);
  const [emailConfirmation, setEmailConfirmation] = useState(false);
  const [evt, setEvt] = useState(false);
  const [geoLocationType, setGeoLocationType] = useState(false);
  const [userPhone, setUserPhone] = useState('');
  const [userEmail, setUserEmail] = useState('');
  const [autoclose, setAutoclose] = useState(false);
  const [autocloseMessage, setAutocloseMessage] = useState('');
  const [autocloseMinutes, setAutocloseMinutes] = useState(0);
  const [lastPageText, setLastPageText] = useState({
    caption: '',
    description: ''
  });
  const [firstPageText, setFirstPageText] = useState({
    button: '',
    caption: '',
    counter: '',
    description: ''
  });
  const [surveyIsCompleted, setSurveyIsCompleted] = useState(true);
  const [surveyIsNotFound, setSurveyIsNotFound] = useState(false);

  const id = getQueryParam('id');

  const setPhoneNumber = (phone: string) => {
    setUserPhone(phone);
  };

  const setLastPageTextTest = useCallback(
    (lastPageText: any) => {
      setLastPageText(lastPageText);
    },
    [setLastPageText]
  );

  const setEmail = (email: string) => {
    setUserEmail(email);
  };

  const changeIsSingleError = (value: boolean) => {
    setIsSingleError(value);
  };

  const changeIsQuestionAnswered = useCallback(
    (value: boolean) => {
      if (value !== isQuestionAnswered) {
        setIsQuestionAnswered(value);
      }
    },
    [isQuestionAnswered]
  );

  const changeIsQuestionTouched = (value: boolean) => {
    setIsQuestionTouched(value);
  };

  const changeQuestionValue = (question: QuestionModel, value: any) => {
    if (!question) return;

    const { questionId: qId, attr_name } = question;

    const questionId = qId;

    if (value)
      setValidation(
        validation?.filter((item: any) => item.questionId !== questionId)
      );

    setResults(
      produce(results, (draft: any) => {
        const questionToChange = questions.find(
          item => item.questionId === questionId
          // || item.attr_name === questionId
        );

        const answerToChange = questionToChange?.answers[0];

        const changeEndQuestionaryText = answerToChange?.list?.filter(
          x => x.text === value
        );

        if (
          changeEndQuestionaryText?.length > 0 &&
          changeEndQuestionaryText?.[0]?.endQuestionary &&
          changeEndQuestionaryText?.[0]?.endQuestionaryText
        ) {
          setLastPageText(changeEndQuestionaryText?.[0]?.endQuestionaryText);
        } else {
          setLastPageText(survey.template.template.finalPageText);
        }

        switch (answerToChange?.type) {
          case AnswerTypes.phone:
            if (attr_name) {
              draft[attr_name] = '380' + value.replaceAll(' ', '');
            }

            draft[questionId] = '380' + value.replaceAll(' ', '');

            break;

          case AnswerTypes.text:
          case AnswerTypes.scale:
          case AnswerTypes.dropdown:
          case AnswerTypes.geoLocation:
          case AnswerTypes.inn:
          case AnswerTypes.email_pmi:
          case AnswerTypes.text_small:
          case AnswerTypes.slider:
          case AnswerTypes.radiobutton: {
            changeIsQuestionAnswered(true);

            if (attr_name) {
              draft[attr_name] = value;
            }

            draft[questionId] = value;

            break;
          }
          case AnswerTypes.radiobuttonRedesign: {
            changeIsQuestionAnswered(true);

            if (attr_name) {
              draft[attr_name] = value;
            }

            draft[questionId] = value;

            break;
          }

          case AnswerTypes.radiobuttonMultiple:
          case AnswerTypes.dropdownMultiple:
          case AnswerTypes.dropdownMultipleSingle:
          case AnswerTypes.numberMultiple:
          case AnswerTypes.demicalMultiple:
          case AnswerTypes.checkbox:
          case AnswerTypes.checkboxRedesign:
          case AnswerTypes.checkboxNumberMultiple:
          case AnswerTypes.familiarityScalePlaceRotation:
          case AnswerTypes.familiarityScaleRange:
          case AnswerTypes.percentsMultiple: {
            if (attr_name) {
              draft[attr_name] = value;
            }

            draft[questionId] = value;

            break;
          }

          case AnswerTypes.familiarityScaleRangeSingle: {
            if (attr_name) {
              draft[attr_name] = Object.values(value)?.[0];
            }

            draft[questionId] = Object.values(value)?.[0];

            break;
          }

          case AnswerTypes.birthYear: {
            const key = months.includes(value) ? 'month' : 'year';

            if (attr_name) {
              draft[attr_name] = { ...draft[questionId], [key]: value };
            }

            draft[questionId] = { ...draft[questionId], [key]: value };

            break;
          }

          case AnswerTypes.buttons: {
            if (attr_name) {
              draft[attr_name] = { text: value };
            }

            draft[questionId] = { text: value };

            break;
          }

          case AnswerTypes.upload: {
            const locations = value?.map((item: any) => item?.Location);

            if (attr_name) {
              draft[attr_name] = locations;
            }

            draft[questionId] = locations;

            break;
          }

          case AnswerTypes.text_regexp: {
            if (attr_name) {
              draft[attr_name] = value;
            }

            draft[questionId] = value;

            break;
          }

          case AnswerTypes.date: {
            if (attr_name) {
              draft[attr_name] = value;
            }

            draft[questionId] = value;

            break;
          }

          case AnswerTypes.time: {
            if (attr_name) {
              draft[attr_name] = value;
            }

            draft[questionId] = value;

            break;
          }

          case AnswerTypes.time_range: {
            if (attr_name) {
              draft[attr_name] = value;
            }

            draft[questionId] = value;

            break;
          }

          case AnswerTypes.visitFeedback: {
            draft[questionId] = value;

            break;
          }
        }
      })
    );
  };

  const sendAnswers = (isClosePage?: boolean) => {
    submitSurvey({
      survey,
      isIndividualSurvey,
      results,
      userPhone,
      userEmail,
      isClosePage
    });
  };

  /**
   * Get Form values on startup
   */
  useEffect(() => {
    //change to ZYN
    const isZyn =
      window.location.href.indexOf('zyn') >= 0 ||
      window.location.href.indexOf('d5dymzw56a71j') >= 0;

    if (isZyn) {
      document.documentElement.style.setProperty('--aqua-blue', '#00A9E0');
      document.documentElement.style.setProperty('--dark-grey', '#003B5E');
      document.documentElement.style.setProperty(
        '--font',
        'GothamPro, sans-serif'
      );
      document.getElementById('one_trust').dataset.domainScript =
        '0195b72e-f741-73c0-a9d0-bef08f72684c';
      // @ts-ignore
      document.querySelector("link[rel~='icon']").href = '/fav_zyn.svg';
      document.title = 'ZYN Survey';
    }
    setLoading(true);
    setSurveyIsNotFound(false);

    getSurvey(id as string).then(survey => {
      setLoading(false);

      if (!survey) {
        setSurveyIsNotFound(true);
        return;
      }

      const {
        template: { template: res }
      } = survey;

      setSurvey(survey);
      setIsIndividualSurvey(!!survey.phone);

      res.questions?.questions.forEach((question: any) => {
        if (question.answers[0].type === 'geo_location') {
          setGeoLocationType(true);
        }
      });
      setUserPhone(survey.phone);
      setUserEmail(survey.email);
      setLastPageText({
        caption: 'Ти вже проходив це опитування.<br>Дякуємо!',
        description: ''
      });
      setAutoclose(res.autoclose);
      setAutocloseMessage(res.autoclose_message);
      setAutocloseMinutes(res.autoclose_minutes);
      setFirstPageText(res.startPageText);
      setData((res?.questions as any) || null);
      setClientId(res?.client_id);
      setMobileConfirmation(res?.mobileConfirmation);
      setEmailConfirmation(res?.emailConfirmation);
      setEvt(res?.evt);

      if (!res) return;

      setResults(
        res.questions?.questions?.reduce(
          (results: any, question: any) => ({
            ...results,
            [question?.questionId]: ''
          }),
          {}
        )
      );
      setValidation(
        res.questions?.questions?.filter((item: any) => item.required) as any
      );

      if (!survey.phone) {
        setSurveyIsCompleted(false);

        return;
      }

      setSurveyIsCompleted(survey.completed);
    });
  }, [id]);

  return {
    loading,
    data: (data as unknown) as Survey,
    id,
    changeQuestionValue,
    changeIsQuestionAnswered,
    changeIsQuestionTouched,
    changeIsSingleError,
    results,
    questions,
    setResults,
    validation,
    isQuestionAnswered,
    isQuestionTouched,
    isSingleError,
    clientId,
    mobileConfirmation,
    emailConfirmation,
    evt,
    geoLocationType,
    setPhoneNumber,
    setLastPageTextTest,
    setEmail,
    setSurveyIsCompleted,
    sendAnswers,
    lastPageText,
    firstPageText,
    autoclose,
    autocloseMessage,
    autocloseMinutes,
    surveyIsCompleted,
    userPhone,
    survey,
    surveyIsNotFound
  };
};

export { useFormData };
