import React, { Fragment } from 'react';
import { hoc, ProgressBar } from 'src/core';
import { QuestionModel } from 'src/api';
import { Question } from './question';
import { useSurveyProps } from './survey.props';
import styles from './survey.module.scss';

/**
 * <Survey />
 */
const Survey = hoc(
  useSurveyProps,
  ({ question, questions, validation, setIsValueEmpty }) => (
    <Fragment>
      <div className={styles.survey}>
        {question?.answers?.[0]?.type !== 'visit_feedback' && (
          <ProgressBar questions={questions} />
        )}
        <div className={styles.content}>
          {!!question?.text && (
            <div
              className={styles.content__caption}
              dangerouslySetInnerHTML={{ __html: `${question?.text}` }}
            />
          )}
          {question?.description && (
            <div
              className={styles.content__description}
              dangerouslySetInnerHTML={{
                __html: `${question?.description ? question?.description : ''}`
              }}
            />
          )}
          <div className={styles.content__section}>
            <Question
              question={question as QuestionModel}
              validation={validation as any}
              setIsValueEmpty={setIsValueEmpty}
            />
          </div>
        </div>
      </div>
      {/* <Navigation /> */}
    </Fragment>
  )
);

export { Survey };
