export const cities = [
  {
    label: 'Київ'
  },
  {
    label: 'Одеса'
  },
  {
    label: 'Харків'
  },
  {
    label: 'Дніпро'
  },
  {
    label: 'Львів'
  },
  {
    label: 'Інше',
    showTextField: true
  }
];

export const channelsWithAddress = [
  'Наш фірмовий магазин',
  'Алло',
  'Фортуна',
  'LCE',
  'КА ВГТ'
];

export const channels = [
  {
    label: 'Наш фірмовий магазин'
  },
  {
    label: 'Алло'
  },
  {
    label: 'Фортуна'
  },
  {
    label: 'LCE'
  },
  {
    label: 'Контакт-центр'
  },
  {
    label: 'КА/ВГТ/GT'
  },
  {
    label: 'Експрес доставка'
  },
  {
    label: 'Нова Пошта'
  }
];
