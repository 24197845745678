import classNames from 'classnames';
import styles from './file-input.module.scss';
import upload from '../../../../assets/images/upload.svg';
import uploadSuccess from '../../../../assets/images/upload-success.svg';
import { sendMedia } from 'src/api';
import React, {
  ChangeEvent,
  Fragment,
  useEffect,
  useRef,
  useState
} from 'react';
import { ErrorMessage } from '../../error-message';

type InputProps = {
  accept: string;
  value?: Record<string, string>;
  answers: Array<{
    uploadFileExtensions: string;
    uploadFileExtension_error_text: string;
    uploadSize: string;
    uploadSize_error_text: string;
  }>;
  handleChangeFileUrls: (fileUrl: string) => void;
};

const FileInput = ({
  accept,
  value,
  answers,
  handleChangeFileUrls
}: Partial<InputProps>) => {
  const [fileUrl, setFileUrl] = useState('');
  const [isErrorSize, setIsErrorSize] = useState(false);
  const [isErrorExtension, setIsErrorExtension] = useState(false);
  const inputRef = useRef<HTMLInputElement>(null);

  const {
    uploadFileExtensions,
    uploadFileExtension_error_text: uploadFileExtensionErrorText,
    uploadSize,
    uploadSize_error_text: uploadSizeErrorText
  } = answers[0];

  const onUploadClick = () => {
    inputRef.current?.click();
  };

  const onChange = (event: ChangeEvent<HTMLInputElement>) => {
    const {
      target: { files }
    } = event;

    if (uploadFileExtensions) {
      const fileExtension = files[0].name.split('.').pop();
      const isValidExtension = uploadFileExtensions?.includes(fileExtension);
      setIsErrorExtension(!isValidExtension);
    }

    if (uploadSize) {
      const maxSize = Number(uploadSize) * 1048576;
      const isValidSize = files[0].size <= maxSize;
      setIsErrorSize(!isValidSize);
    }

    if (!files) return;

    sendMedia(files[0]).then(res => {
      const file = res?.data;

      setFileUrl(file);

      if (!handleChangeFileUrls) return;

      handleChangeFileUrls(file);
    });
  };

  useEffect(() => {
    setFileUrl(value as any);
  }, [value]);

  return (
    <Fragment>
      <div
        className={classNames(styles.container, {
          [styles.input_error]: isErrorSize || isErrorExtension,
          [styles.file_exists]: !!fileUrl
        })}
        onClick={onUploadClick}
      >
        <img src={!!fileUrl ? uploadSuccess : upload} alt='Upload' />

        <p>{!!fileUrl ? 'Зображення завантажено' : 'Тисни, щоб завантажити'}</p>

        <input
          className={styles.input}
          disabled={!!fileUrl}
          type='file'
          accept={accept}
          ref={inputRef}
          onChange={onChange}
        />
      </div>

      {isErrorSize && (
        <ErrorMessage
          error={uploadSizeErrorText}
          isError={isErrorSize}
          className={styles.error_text}
        />
      )}

      {isErrorExtension && (
        <ErrorMessage
          error={uploadFileExtensionErrorText}
          isError={isErrorExtension}
          className={styles.error_text}
        />
      )}
    </Fragment>
  );
};

export default FileInput;
