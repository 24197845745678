import { List, ListVariant } from 'src/api';
import { useStore } from 'src/context';
import { isObject } from '../utils';

/**
 * Use Dependency List Answers
 */
const useDependencyListAnswersCheckboxMultiple = <T extends ListVariant | List>(
  list: T[] = []
) => {
  const { results } = useStore();

  return list.filter(listItem => {
    if (!listItem.dependency) {
      return true;
    }

    const { answers = '', questionId } = listItem.dependency;

    const currentResult = results[questionId];

    if (answers.includes('|')) {

      return answers
        .split('|')
        .some(answer => Array.isArray(currentResult) && currentResult.some(current => current === answer));
    }

    if (Array.isArray(currentResult)) {
      return currentResult.some(current => current === answers);
    }

    return;
  });
};

export { useDependencyListAnswersCheckboxMultiple };
