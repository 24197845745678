import { List, ListVariant } from 'src/api';
import { useStore } from 'src/context';
import { isObject } from '../utils';

/**
 * Use Dependency List Answers
 */
const useDependencyNotShowListAnswers = <T extends ListVariant | List>(
  list: T[] = []
) => {
  const { results } = useStore();

  return list.filter(listItem => {
    if (!listItem.dependency_not_show) {
      return true;
    }

    const { answers = '', questionId } = listItem.dependency_not_show;

    const currentResult = results[questionId];
    
    if (Array.isArray(currentResult)) {
      return currentResult.some(current => current !== answers);
    }

    if (isObject(currentResult)) {
      return answers
        .split('|')
        .some(answer => currentResult[answer] === undefined);
    }

    return;
  });
};

export { useDependencyNotShowListAnswers };
