import { useEffect, useLayoutEffect } from 'react';
import { Answer, QuestionModel } from 'src/api';
import { useStore } from 'src/context';
import {
  getTextFromCheckboxQuestionValue,
  isObject,
  isString
} from '../../utils';
import { useAgeFilteredList, useDependencyNotShow,useDependencyListAnswers } from '../../hooks';
import {
  CheckboxQuestionCommentValue,
  CheckboxQuestionProps
} from './checkbox-question.type';
import { useLimitElementsMax } from '../../hooks';

/**
 * <CheckboxQuestion /> props
 */
const useCheckboxQuestionProps = (props: Partial<CheckboxQuestionProps>) => {
  const { value, question, onChange } = props;

  const { list: filteredByAgeList } = useAgeFilteredList(
    (question as QuestionModel).answers[0].list
  );

  var filteredList = useDependencyNotShow<Answer>(filteredByAgeList);

  filteredList = useDependencyListAnswers<Answer>(filteredList);

  const { changeIsQuestionAnswered } = useStore();

  const isBigList = filteredList.length >= 5;

  const valuesAsArray = Array.isArray(value) ? value : [];

  const commentValues = valuesAsArray.filter(
    isObject
  ) as CheckboxQuestionCommentValue[];

  const valuesWithComment = commentValues.map(({ answer }) => answer);
  const valuesWithoutComment = valuesAsArray.filter(isString) as string[];

  const onCheckboxClick = (
    item: string,
    { comment, exclusive }: { exclusive?: boolean; comment?: boolean }
  ) => {
    if (typeof value === 'string') return;

    if (exclusive) {
      onChange?.(
        question as QuestionModel,
        comment ? [{ answer: item, comment: '' }] : [item]
      );

      return;
    }

    if (value?.map(getTextFromCheckboxQuestionValue).includes(item)) {
      onChange?.(
        question as QuestionModel,
        value.filter(text => getTextFromCheckboxQuestionValue(text) !== item)
      );

      return;
    }

    const valuesWithoutExclusive =
      value?.filter(
        text =>
          !filteredList.find(
            listItem => listItem.text === getTextFromCheckboxQuestionValue(text)
          )?.exclusive
      ) || [];

    const newValue = comment ? { answer: item, comment: '' } : item;

    onChange?.(question as QuestionModel, [
      ...valuesWithoutExclusive,
      newValue
    ]);
  };

  const {
    isError: isLimitElementsMaxError,
    errorMessage: limitElementsMaxMessage
  } = useLimitElementsMax(props.question as QuestionModel);

  const onCommentChange = (name: string, comment: string) => {
    if (typeof value === 'string') return;

    const newValue = value?.map(text =>
      getTextFromCheckboxQuestionValue(text) === name
        ? { ...(text as CheckboxQuestionCommentValue), comment }
        : text
    );

    onChange?.(question as QuestionModel, newValue as any);
  };

  //fix first render value = string
  useLayoutEffect(() => {
    if (Array.isArray(value)) return;

    onChange?.(question as QuestionModel, []);
  }, []);

  useEffect(() => {
    changeIsQuestionAnswered(
      !isLimitElementsMaxError &&
        Array.isArray(value) &&
        !!value.length &&
        value.every(text => {
          if (typeof text === 'string') return !!text.length;

          const { answer, comment } = text;

          return !!answer.length && !!comment.length;
        })
    );
  }, [value]);

  return {
    isBigList,
    filteredList,
    commentValues,
    valuesWithComment,
    valuesWithoutComment,
    onCheckboxClick,
    onCommentChange,
    isLimitElementsMaxError,
    limitElementsMaxMessage,
    ...props
  };
};

export { useCheckboxQuestionProps };
