import { ChangeEvent, useEffect, useState } from 'react';
import { ListVariant, QuestionModel } from 'src/api';
import { useStore } from 'src/context';
import { commentCasePrefix } from '../../utils/constants';
import {
  useDependencyNotShow,
  useDependencyNotShowListAnswers,
  useDependencyListAnswersCheckboxMultiple,
  useLimitSell,
  useLimitSellMin,
  useLimitSum,
  useValueAsObject,
  useNumberError
} from '../../hooks';
import {
  toCommentCase,
  isOnlyNumbers,
  removeDuplicateCommentOptions
} from '../../utils';
import {
  CheckboxNumberMultipleProps,
  CheckboxNumberMultipleValue
} from './checkbox-number-multiple.type';
import {
  createObjectFromKeys,
  isAnswered
} from './checkbox-number-multiple.utils';

/**
 * <CheckboxNumberMultiple /> props
 */
const useCheckboxNumberMultipleProps = (
  props: Partial<CheckboxNumberMultipleProps>
) => {
  const [exclusiveTexts, setExclusiveTexts] = useState<string[]>([]);
  const [commentTexts, setCommentsTexts] = useState<string[]>([]);
  const [checkboxTexts, setCheckboxTexts] = useState<string[]>([]);

  const { changeIsQuestionAnswered } = useStore();

  var variants = useDependencyNotShow<ListVariant>(
    props.question?.answers[0].list_variant
  );

  variants = useDependencyNotShowListAnswers<ListVariant>(
    props.question?.answers[0].list_variant
  );

  variants = useDependencyListAnswersCheckboxMultiple<ListVariant>(
    variants
  );
  
  
  const imageStyle = props.question?.image_style;

  const currentValue = useValueAsObject(props.value, () => {
    const newValue =
      typeof props.value === 'string'
        ? variants
            .filter(({ checked }) => checked)
            .reduce((acc, { text, comment }) => {
              const base = {
                ...acc,
                [text]: ''
              };

              return comment ? { ...base, [toCommentCase(text)]: '' } : base;
            }, {} as CheckboxNumberMultipleValue)
        : props.value;

    const exclusiveTexts = variants
      .filter(({ exclusive }) => exclusive)
      .map(({ text }) => text);

    const commentTexts = variants
      .filter(({ comment }) => comment)
      .map(({ text }) => text);

    const checkboxTexts = variants
      .filter(({ checkbox }) => checkbox)
      .map(({ text }) => text);

    setCheckboxTexts(checkboxTexts);
    setCommentsTexts(commentTexts);
    setExclusiveTexts(exclusiveTexts);
    props.onChange?.(props.question as QuestionModel, newValue as any);
  });

  const {
    isError: isLimitSumError,
    errorMessage: limitSumErrorMessage
  } = useLimitSum(props.question as QuestionModel);

  const {
    isError: isLimitSellError,
    errorMessage: limitSellErrorMessage,
    keys: limitSellErrorKeys
  } = useLimitSell(props.question as QuestionModel);

  const {
    isError: isLimitSellMinError,
    errorMessage: limitSellMinErrorMessage,
    keys: limitSellMinErrorKeys
  } = useLimitSellMin(props.question as QuestionModel);

  const {
    isError: isNumberError,
    errorMessage: numberErrorMessage,
    keys: numberErrorMessageKeys
  } = useNumberError(props.question as QuestionModel);

  const onCheckboxChange = (text: string) => {
    const keys = Object.keys(currentValue);

    if (keys.includes(text) || keys.includes(toCommentCase(text))) {
      const newValue = createObjectFromKeys(
        keys.filter(key => key !== text && key !== toCommentCase(text)),
        currentValue
      );

      props.onChange?.(props.question as QuestionModel, newValue);
      return;
    }

    const isExclusive = exclusiveTexts.includes(text);
    const isComment = commentTexts.includes(text);
    const isCheckbox = checkboxTexts.includes(text);

    const newValueBase = { [text]: '' };
    const newCommentBase = { [toCommentCase(text)]: '' };
    const newCheckboxBase = { [text]: 'checkbox' };
    const newTextBase = isComment
      ? { ...newValueBase, ...newCommentBase }
      : newValueBase;
    const newBase = isCheckbox ? newCheckboxBase : newTextBase;

    const valueWithoutExclusive = createObjectFromKeys(
      keys.filter(text => !exclusiveTexts.includes(text)),
      currentValue
    );

    const newValue = isExclusive
      ? newBase
      : { ...valueWithoutExclusive, ...newBase };

    props.onChange?.(props.question as QuestionModel, newValue);
  };

  const [isNumberErrorField, setIsNumberError] = useState(false);

  function onlyNumbers(str) {
    const regex1 = /^[0-9]+$/;
    return regex1.test(str);
  }

  const onInputChange = (
    event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    const { value, name } = event.target;
    if (onlyNumbers(value)) {
      setIsNumberError(false);
    } else {
      setIsNumberError(true);
    }

    props.onChange?.(props.question as QuestionModel, {
      ...currentValue,
      [name]: value
    });
  };

  useEffect(() => {
    const asArray = Object.entries(currentValue);
    const filtered = asArray.filter(
      ([key, value]) => !(key.includes(commentCasePrefix) && value === '')
    );
    const values = Object.fromEntries(filtered);
    const filteredValues = removeDuplicateCommentOptions(values);
    changeIsQuestionAnswered(
      !isNumberError &&
        !isLimitSumError &&
        !isLimitSellError &&
        !isLimitSellMinError &&
        !!Object.keys(filteredValues).length &&
        Object.values(filteredValues).every(isAnswered)
    );
  }, [currentValue]);

  return {
    isLimitSellError,
    limitSellErrorMessage,
    limitSellErrorKeys,
    isLimitSumError,
    isLimitSellMinError,
    limitSellMinErrorMessage,
    limitSellMinErrorKeys,
    limitSumErrorMessage,
    variants,
    onInputChange,
    onCheckboxChange,
    numberErrorMessage,
    numberErrorMessageKeys,
    isNumberError,
    isNumberErrorField,
    imageStyle,
    ...props
  };
};

export { useCheckboxNumberMultipleProps };
