import { FC } from 'react';
import { AnswersType } from 'src/api';
import { PhoneInput } from 'src/core';
import { TextQuestion } from './text-question';
import { TextSmallQuestion } from './text-small-question';
import { CheckboxQuestion } from './checkbox-question';
import { RadioQuestion } from './radio-question';
import { EmailPMI } from './email-pmi-question';
import { ScaleQuestion } from './scale-question';
import { SliderQuestion } from './slider-question';
import { DropdownQuestion } from './dropdown-question';
import { BirthYearQuestion } from './birth-year-question';
import { GeoLocationQuestion } from './geo-location-question';
import { ButtonsQuestion } from './buttons-question';
import { UploadQuestion } from './upload-question';
import { InnQuestion } from './inn-question';
import { RadioTable } from './radio-table';
import {
  MultipleDemicalQuestion,
  MultipleDropdownsQuestion,
  MultipleNumbersQuestion
} from './multiple';
import { CheckboxNumberMultiple } from './checkbox-number-multiple';
import { FamiliarityScalePlaceRotation } from './familiarity-scale-place-rotation';
import { FamiliarityScaleRange } from './familiarity-scale-range';
import { TextRegexpQuestion } from './text-regexp-question';
import { DateQuestion } from './date-question';
import { TimeQuestion } from './time-question';
import { TimeRangeQuestion } from './time-range-question';
import { PercentMultiple } from './percent-multiple';
import { CheckboxQuestionRedesign } from './checkbox-question-redesign';
import { RadioQuestionRedesign } from './radio-question-redesign';
import { VisitFeedback } from './visit-feedback';

/**
 * Render controls by type
 */
const controlsComponents: Record<AnswersType, FC<any> | any> = {
  text: TextQuestion,
  text_small: TextSmallQuestion,
  checkbox: CheckboxQuestion,
  radiobutton: RadioQuestion,
  checkbox_redesign: CheckboxQuestionRedesign,
  radiobutton_redesign: RadioQuestionRedesign,
  scale: ScaleQuestion,
  email_pmi: EmailPMI,
  slider: SliderQuestion,
  dropdown: DropdownQuestion,
  geo_location: GeoLocationQuestion,
  buttons: ButtonsQuestion,
  upload: UploadQuestion,
  phone: PhoneInput,
  inn: InnQuestion,
  birth_year: BirthYearQuestion,
  radiobutton_multiple: RadioTable,
  dropdown_multiple: MultipleDropdownsQuestion,
  dropdown_multiple_single: MultipleDropdownsQuestion,
  number_multiple: MultipleNumbersQuestion,
  demical_multiple: MultipleDemicalQuestion,
  checkbox_number_multiple: CheckboxNumberMultiple,
  familiarity_scale_place_rotation: FamiliarityScalePlaceRotation,
  familiarity_scale_range: FamiliarityScaleRange,
  familiarity_scale_range_single: FamiliarityScaleRange,
  text_regexp: TextRegexpQuestion,
  date: DateQuestion,
  time: TimeQuestion,
  time_range: TimeRangeQuestion,
  percents_multiple: PercentMultiple,
  visit_feedback: VisitFeedback
};

export { controlsComponents };
