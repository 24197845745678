import React, { Fragment, useEffect } from 'react';
import Calendar from 'react-calendar';
import moment from 'moment';
import { PhoneInput, RadioRedesign } from 'src/core';
import { useStore } from 'src/context';
import FileInput from '../../../../../core/components/upload/components/file-input.component';
import closeSVG from '../../../../../assets/images/close.svg';
import {
  channels,
  channelsWithAddress,
  cities
} from './visit-feedback.constants';
import { VisitFeedbackProps } from './visit-feedback.props';
import styles from './visit-feedback.module.scss';

/**
 * <VisitFeedback />
 */
const VisitFeedback: React.FC<VisitFeedbackProps> = ({
  value,
  onChange,
  question
}) => {
  const { changeIsQuestionAnswered } = useStore();

  useEffect(() => {
    const isChannelWithAddress = channelsWithAddress.some(
      item => item === value?.channel
    );

    const isRequiredFieldsFilled =
      !!value?.name?.trim() &&
      !!value?.phone &&
      value?.phone?.replaceAll(' ', '').length === 12 &&
      !!value?.city?.trim() &&
      !!value?.date &&
      !!value?.comments?.trim();

    const isQuestionAnswered = isChannelWithAddress
      ? isRequiredFieldsFilled && !!value?.storeAddress?.trim()
      : isRequiredFieldsFilled;

    changeIsQuestionAnswered(isQuestionAnswered);
  }, [value, changeIsQuestionAnswered]);

  return (
    <div className={styles.container}>
      <div className={styles.field}>
        <p className={styles.title}>Впиши своє ім’я та прізвище</p>
        <input
          className={styles.input}
          value={value?.name}
          onChange={event =>
            onChange(question, { ...value, name: event.target.value })
          }
        />
      </div>

      <div className={styles.field}>
        <p className={styles.title}>
          Будь ласка, напиши свій номер у форматі 380ХХХХХХХХ (бажано,
          зареєстрований в нашій системі)
        </p>
        <PhoneInput
          value={value?.phone || ''}
          onChange={phone =>
            onChange(question, {
              ...value,
              phone: `380${phone?.replaceAll(' ', '')}`
            })
          }
          skipQuestionAnsweredCheck
        />
      </div>

      <div className={styles.field}>
        <p className={styles.title}>
          Напиши місто у якому ти зробив візит українською мовою
        </p>
        <div className={styles.cities}>
          {cities.map((item, index) => (
            <div key={index} className={styles.radio}>
              <RadioRedesign
                label={item.label}
                value={value?.city === item.label}
                question={question}
                onChange={(_, option) => {
                  const { cityName, ...rest } = value || {};

                  onChange(question, { ...rest, city: option });
                }}
              />
              {!!item.showTextField && (
                <textarea
                  disabled={value?.city !== cities[cities.length - 1].label}
                  className={styles.radio_textarea}
                  value={value?.cityName || ''}
                  onChange={event =>
                    onChange(question, {
                      ...value,
                      cityName: event.target.value
                    })
                  }
                />
              )}
            </div>
          ))}
        </div>
      </div>

      <div className={styles.field}>
        <p className={styles.title}>Вкажи дату візиту</p>
        <Calendar
          value={value?.date}
          onChange={v =>
            onChange(question, {
              ...value,
              date: moment(v.toString()).format('YYYY-MM-DD')
            })
          }
        />
      </div>

      <div className={styles.field}>
        <p className={styles.title}>Який канал ти перевірив?</p>
        <div className={styles.cities}>
          {channels.map((item, index) => (
            <div key={index} className={styles.radio}>
              <RadioRedesign
                label={item.label}
                value={value?.channel === item.label}
                question={question}
                onChange={(_, option) =>
                  onChange(question, { ...value, channel: option })
                }
              />
            </div>
          ))}
        </div>
      </div>

      {channelsWithAddress.some(item => item === value?.channel) && (
        <div className={styles.field}>
          <p className={styles.title}>Впиши адресу магазину</p>
          <input
            className={styles.input}
            value={value?.storeAddress}
            onChange={event =>
              onChange(question, { ...value, storeAddress: event.target.value })
            }
          />
        </div>
      )}

      <div className={styles.field}>
        <p className={styles.title}>
          Коментарі до візиту (що було добре, а що потрібно покращити?)
        </p>
        <textarea
          className={styles.textarea}
          value={value?.comments}
          onChange={event =>
            onChange(question, { ...value, comments: event.target.value })
          }
        />
      </div>

      <div>
        <p className={styles.title}>Додати фото</p>
        <Fragment>
          {Array.from({ length: (value?.images?.length || 0) + 1 }).map(
            (_, index) => (
              <div key={index} className={styles.image}>
                {value?.images?.length > 0 && index !== value?.images?.length && (
                  <img
                    className={styles.image_close}
                    src={closeSVG}
                    alt='Close'
                    onClick={() =>
                      onChange(question, {
                        ...value,
                        images: value?.images?.filter((_, i) => i !== index)
                      })
                    }
                  />
                )}
                <FileInput
                  accept='image/*'
                  answers={[{} as any]}
                  value={value?.images?.[index] || ('' as any)}
                  handleChangeFileUrls={file =>
                    onChange(question, {
                      ...value,
                      images: [...(value?.images || []), file]
                    })
                  }
                />
              </div>
            )
          )}
        </Fragment>
      </div>
    </div>
  );
};

export { VisitFeedback };
